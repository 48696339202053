<template>
  <div></div>
</template>
  
  <script>
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  created() {
    window.location.href = process.env.VUE_APP_MARKETING_URL;
  },
  methods: {},
};
</script>
  
  <style scoped lang="postcss"></style>
  